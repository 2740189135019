// extracted by mini-css-extract-plugin
export var captionDiv = "terms-conditions-module--captionDiv--IvrNN";
export var captionDiv22 = "terms-conditions-module--captionDiv22--JRZZi";
export var displayB = "terms-conditions-module--displayB--9s+hL";
export var displayDiv = "terms-conditions-module--displayDiv--OVOEa";
export var displayDiv2 = "terms-conditions-module--displayDiv2--Tdk4m";
export var docTemplateDiv = "terms-conditions-module--docTemplateDiv--p4PqE";
export var gToUpload = "terms-conditions-module--gToUpload--wIJDh";
export var inAdditionTo = "terms-conditions-module--inAdditionTo--FBNvd";
export var pDiv = "terms-conditions-module--pDiv--gWqsf";
export var pDiv1 = "terms-conditions-module--pDiv1--PToBY";
export var privacyPolicyB = "terms-conditions-module--privacyPolicyB--C3d5+";
export var returnsPolicySpan = "terms-conditions-module--returnsPolicySpan--zrVfL";
export var subtitleDiv = "terms-conditions-module--subtitleDiv--wVkBc";
export var subtitleLargeDiv = "terms-conditions-module--subtitleLargeDiv--H4FpM";
export var termsConditions = "terms-conditions-module--termsConditions--llGUU";
export var textColumnDiv = "terms-conditions-module--textColumnDiv--zKCJT";
export var textbodyDiv = "terms-conditions-module--textbodyDiv--7BInX";
export var textbodyDiv4 = "terms-conditions-module--textbodyDiv4--z0foN";
export var titleDiv = "terms-conditions-module--titleDiv--Fu6Ew";